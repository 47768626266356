// Flag Target on Base
function onSpecificElement(target, base, className = "-on") {
  const domTarget = document.querySelector(target);
  const domBase = document.querySelector(base);
  if (domTarget instanceof HTMLElement && domBase instanceof HTMLElement) {
    let targetY = domTarget.getBoundingClientRect().top;
    let boundaryBottom = targetY + domBase.clientHeight - domTarget.clientHeight / 2;
    if (window.pageYOffset >= boundaryBottom) {
      domTarget.classList.remove(className);
    } else {
      domTarget.classList.add(className);
    }
  } else if (domTarget instanceof HTMLElement) {
    domTarget.classList.remove(className);
  }
}

// Flag Target out Base
function outSpecificElement(target, base, className = "-out") {
  const domTarget = document.querySelector(target);
  const domBase = document.querySelector(base);
  if (domTarget instanceof HTMLElement && domBase instanceof HTMLElement) {
    let targetY = domTarget.getBoundingClientRect().top;
    let boundaryBottom = targetY + domBase.clientHeight - domTarget.clientHeight / 2;
    if (window.pageYOffset >= boundaryBottom) {
      domTarget.classList.add(className);
    } else {
      domTarget.classList.remove(className);
    }
  } else if (domTarget instanceof HTMLElement) {
    domTarget.classList.remove(className);
  }
}

// Flag Target on Top
function topSpecificElement(target, className = "-scroll") {
  const domTarget = document.querySelector(target);
  if (domTarget instanceof HTMLElement) {
    let targetY = domTarget.getBoundingClientRect().top;
    if (window.pageYOffset > targetY) {
      domTarget.classList.add(className);
    } else {
      domTarget.classList.remove(className);
    }
  } else if (domTarget instanceof HTMLElement) {
    domTarget.classList.remove(className);
  }
}

export { onSpecificElement, outSpecificElement, topSpecificElement };
