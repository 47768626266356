import { userAgentIos } from "./userAgentCheck.js";
function setParallax() {
  const domParallax = document.querySelector(".js-parallax");
  if (domParallax) {
    import(/* webpackChunkName: "rellax" */ "rellax").then((Rellax) => {
      if (domParallax && userAgentIos) {
        let parallax = new Rellax.default(".js-parallax", {
          speed: -1,
          center: true,
          wrapper: null,
          round: true,
          vertical: true,
          horizontal: false,
        });
      }
    });
  }
}
export { setParallax };
