import { backfaceFixed } from "./backfaceFixed.js";

const domFrame = document.getElementById("js-frame");
const clsMenuOpen = "-open";

function navigationOpen() {
  domFrame.classList.add(clsMenuOpen);
  backfaceFixed(true);
}
function navigationClose() {
  domFrame.classList.remove(clsMenuOpen);
  backfaceFixed(false);
}

export { navigationOpen, navigationClose };
