// import imagesLoaded from "imagesloaded";
const scrollHoverInit = ({ itemScroll = ".js-scroll", article = "#js-article-contents" } = {}) => {
  const domScrollLists = Array.from(document.querySelectorAll("[data-scroll-list]"));
  if (domScrollLists.length > 0) {
    domScrollLists.forEach((domScrollList) => {
      if (domScrollList.nodeName == "#text") {
        return;
      }
      let scrollListAnchor = domScrollList.dataset.scrollAnchor ? domScrollList.dataset.scrollAnchor : domScrollList.dataset.scrollList;
      if (!domScrollList.dataset.scrollAnchor) domScrollList.dataset.scrollAnchor = scrollListAnchor;
      let scrollListItemCount = 0;
      domScrollList.childNodes.forEach((domScrollListItem) => {
        if (domScrollListItem.nodeName == "#text") {
          return;
        }
        domScrollListItem.classList.add(itemScroll);
        domScrollListItem.dataset.scrollBasis = scrollListAnchor;
        domScrollListItem.dataset.scrollDelay = `${scrollListItemCount}`;
        scrollListItemCount += 0.1;
      });
    });
  }
  scrollHover({ itemScroll: itemScroll, article: article });
};

function scrollHover({ itemScroll = ".js-scroll", article = "#js-article-contents" } = {}) {
  const domScrollItems = Array.from(document.querySelectorAll(itemScroll));
  const domArticle = document.querySelector(article);
  const domArticleItem = domArticle ? Array.from(domArticle.childNodes).filter((item) => item.nodeName !== "#text") : [];
  const domListItem = domArticleItem.concat(domScrollItems);
  if (domListItem.length > 0) {
    domListItem.forEach((item) => {
      if (item.nodeName == "#text") {
        return;
      }
      let scrollBasis = item.dataset ? item.dataset.scrollBasis : false;
      let scrollAnchor = scrollBasis ? document.querySelector(`[data-scroll-anchor="${scrollBasis}"]`) : false;
      let target = scrollAnchor ? scrollAnchor : item;
      let targetY = target.getBoundingClientRect().top;
      let scrollDelay = item.dataset.scrollDelay || "0";
      Object.assign(item.style, {
        transitionDelay: `${scrollDelay}s`,
      });
      if ((window.innerHeight / 10) * 8 >= targetY) {
        import(/* webpackChunkName: "imagesLoaded" */ "imagesloaded").then((imagesLoaded) => {
          imagesLoaded.default(item, { background: true }, function () {
            item.classList.add("-scrolled");
          });
        });
      }
    });
  }
}

export { scrollHoverInit, scrollHover };
