// import mixitup from "mixitup";
let Mixer;
const setFilterInit = () => {
  let domProjectsList = document.querySelector("#js-projects-list");
  if (domProjectsList) {
    import(/* webpackChunkName: "mixitup" */ "mixitup").then((mixitup) => {
      Mixer = mixitup.default("#js-projects-list", {
        load: {
          sort: "order:asc" /* default:asc */,
        },
        animation: {
          effects: "fade translateZ(8px)",
          duration: 320,
          nudge: false,
          reverseOut: false,
        },
        classNames: {
          block: "projects" /* mixitup */,
          elementFilter: "filter-btn" /* control */,
        },
        selectors: {
          target: ".filter-item" /* .mix */,
        },
      });
    });
  } else {
    if (Mixer) Mixer.destroy();
  }
};
export { setFilterInit };
