const openListMenu = function (clsMenuOpen = "-open") {
  const openList = document.querySelector("#js-openlist");
  if (openList) {
    const openListContents = openList.querySelectorAll(".js-openlist-contents");
    const openListButtons = openList.querySelectorAll(".js-openlist-button");
    openListButtons.forEach((item) => {
      item.addEventListener("click", function () {
        itemHeight(openListContents);
        if (item.classList.contains(clsMenuOpen) == true) {
          item.classList.remove(clsMenuOpen);
        } else {
          item.classList.add(clsMenuOpen);
        }
      });
    });
  }
};
function itemHeight(contents) {
  contents.forEach((item) => {
    const openListInner = item.querySelector(".js-openlist-inner");
    item.style.height = `${openListInner.clientHeight}px`;
  });
}

export { openListMenu };
