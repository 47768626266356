import anime from "animejs/lib/anime.es.js";

// 離脱時アニメーション
function leaveAnimation(e) {
  anime.timeline().add({
    targets: ".main",
    easing: "easeOutSine",
    duration: 200,
    opacity: [1, 0],
  });
}
// 遷移先表示時アニメーション
function enterAnimation(e) {
  anime.timeline().add({
    targets: ".main",
    easing: "easeInSine",
    duration: 700,
    opacity: [0, 1],
  });
}

// Barba Meta chenged
const replaceHeadTags = (target) => {
  const head = document.head;
  const targetHead = target.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
  const newPageHead = document.createElement("head");
  newPageHead.innerHTML = targetHead;
  const removeHeadTags = ["meta[name='keywords']", "meta[name='description']", "meta[property^='fb']", "meta[property^='og']", "meta[name^='twitter']", "meta[name='robots']", "meta[itemprop]", "link[itemprop]", "link[rel='prev']", "link[rel='next']", "link[rel='canonical']"].join(",");
  const headTags = [...head.querySelectorAll(removeHeadTags)];
  headTags.forEach((item) => {
    head.removeChild(item);
  });
  const newHeadTags = [...newPageHead.querySelectorAll(removeHeadTags)];
  newHeadTags.forEach((item) => {
    head.appendChild(item);
  });
};

// Barba GA send data
// const gaPush = (pagename) => {
//   ga("send", "pageview", pagename);
// };
// // Barba GTag send data
// const gtagPush = (pagename) => {
//   gtag("config", "GTM-W7XQMSS", {
//     page_path: pagename,
//   });
// };

export { leaveAnimation, enterAnimation, replaceHeadTags };
