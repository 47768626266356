/*-----------------------
-------------------------
ユーザーエージェントで分岐
-------------------------
------------------------*/
const classIos = "-ios";
const domFrame = document.getElementById("js-frame");
const userAgentIos = navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i);
if (userAgentIos) {
  domFrame.classList.add(classIos);
}
export { userAgentIos };
