/**-**-**-**-**-**-**-**-**-**/
/** Polyfill
/**-**-**-**-**-**-**-**-**-**/
// import picturefill from "picturefill";
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();
import objectFitImages from "object-fit-images";
objectFitImages();

/**-**-**-**-**-**-**-**-**-**/
/** Packages
/**-**-**-**-**-**-**-**-**-**/
import WebFont from "webfontloader";
import barba from "@barba/core";

/**-**-**-**-**-**-**-**-**-**/
/** Modules
/**-**-**-**-**-**-**-**-**-**/
import { navigationOpen, navigationClose } from "./module/navigationToggle.js";
import { scrollHover } from "./module/scrollHover.js";
import { sctollToTop, smoothScrollTarget, smoothScrollInit, smoothScrollRemove } from "./module/smoothScroll.js";
import { onSpecificElement, outSpecificElement, topSpecificElement } from "./module/scrollSpecificElement.js";
import { setFilterInit } from "./module/settingMixitup.js";
import { setParallax } from "./module/settingRellax.js";
import { openListMenu } from "./module/clickOpenList.js";
import { leaveAnimation, enterAnimation, replaceHeadTags } from "./module/settingBarba.js";

/**-**-**-**-**-**-**-**-**-**/
/** Variable
/**-**-**-**-**-**-**-**-**-**/
const domFrame = document.getElementById("js-frame");
const domMenuButton = document.getElementById("js-menu-button");
const clsMenuOpen = "-open";
let scrollPositionSave = { left: 0, top: 0 };
let barbaScrollX, barbaScrollY;

/**-**-**-**-**-**-**-**-**-**/
/** Function
/**-**-**-**-**-**-**-**-**-**/
/** minWidthDevice **/
import "./module/minWidthDevice.js";
/** scrollSpecificElement **/
function scrollSpecificElement() {
  let domHero = document.querySelector("#js-hero");
  onSpecificElement("#js-header", "#js-hero.-dark");
  if (domHero) {
    outSpecificElement("#js-header", "#js-hero", "-scroll");
  } else {
    topSpecificElement("#js-header");
  }
}

/*-----------------------
-------------------------
DOM構造読み込み完了時
-------------------------
------------------------*/
document.addEventListener("DOMContentLoaded", function () {
  scrollSpecificElement();
  smoothScrollInit();
  setParallax();
  setFilterInit();
  openListMenu();
});

/*-----------------------
-------------------------
WebFont読み込み完了時
-------------------------
------------------------*/
WebFont.load({
  classes: false,
  custom: {
    families: ["adobe-handwriting-ernie", "arno-pro", "dnp-shuei-gothic-gin-std", "arno-pro-caption"],
  },
  loading: function () {},
  active: function () {
    scrollHover();
  },
  inactive: function () {},
});

/*-----------------------
-------------------------
戻るボタンクリック時
-------------------------
------------------------*/
let currentPath, beforePath;
window.addEventListener("popstate", function (e) {
  // console.log(e);
  // console.log(currentPath, beforePath);
  // if (currentPath && beforePath && currentPath === beforePath) {
  //   console.log("fire");
  // } else {
  //   console.log("not fire");
  // }
  scrollPositionSave.top = barbaScrollY;
  scrollPositionSave.left = barbaScrollX;
});

/*-----------------------
-------------------------
ブラウザのブラウザバック、
リロード時のスクロール位置の設定を変更
-------------------------
------------------------*/
if (history.scrollRestoration) {
  history.scrollRestoration = "auto"; // manualだとリロード時などにトップに戻る
}

/*-----------------------
-------------------------
barba.js遷移時
-------------------------
------------------------*/
const barba_prevent = "manage/wp-admin";
barba.init({
  // debug: true,
  // logLevel: "debug",
  prevent: (e) => {
    if (e.href.indexOf(barba_prevent) > -1) {
      return true;
    }
  },
  requestError: (trigger, action, url, response) => {
    if (action === "click" && response.status && response.status === 404) {
      barba.go("/404");
    }
    return false;
  },
  transitions: [
    {
      beforeLeave: (data) => {
        leaveAnimation(data.current.container);
        smoothScrollRemove();
      },
      leave: (data) => {
        return new Promise(function (resolve, reject) {
          setTimeout(function () {
            resolve();
          }, 800);
          barbaScrollX = barba.history.current.scroll.x;
          barbaScrollY = barba.history.current.scroll.y;
          // console.log(barbaScrollX, barbaScrollY);
        });
      },
      beforeEnter: (data) => {
        replaceHeadTags(data.next);
        navigationClose();
      },
      after: (data) => {
        let target = location.hash ? document.querySelector(location.hash) : null;
        if (target) {
          smoothScrollTarget(target, "instant");
        } else {
          window.scrollTo({
            top: scrollPositionSave.top,
            left: scrollPositionSave.left,
            behavior: "instant",
          });
        }
        enterAnimation(data.next.container);
        scrollSpecificElement();
        scrollHover();
        smoothScrollInit();
        setFilterInit();
        setParallax();
        openListMenu();
        scrollPositionSave.top = 0;
        scrollPositionSave.left = 0;
      },
    },
  ],
  views: [
    {
      namespace: "works",
      beforeLeave(data) {},
      beforeEnter(data) {},
      afterLeave(data) {},
    },
    {
      namespace: "singular",
      beforeEnter(data) {},
      afterLeave(data) {},
    },
  ],
});

/*-----------------------
-------------------------
ページスクロール時
-------------------------
------------------------*/
window.onload = function () {
  let ticking = false;
  window.addEventListener(
    "scroll",
    function () {
      if (!ticking) {
        requestAnimationFrame(function () {
          ticking = false;
          scrollSpecificElement();
          scrollHover();
        });
        ticking = true;
      }
    },
    { passive: true }
  );
};

/*-----------------------
-------------------------
ヘッダークリック時
-------------------------
------------------------*/
domMenuButton.addEventListener("click", function () {
  if (domFrame.classList.contains(clsMenuOpen) == true) {
    navigationClose();
  } else {
    navigationOpen();
  }
});
