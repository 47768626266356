// Smooth Scroll Function
import { navigationClose } from "./navigationToggle.js";
let removeObj = [];
let domScrollTriggers = null;

const domFrame = document.getElementById("js-frame");
const clsMenuOpen = "-open";

const sctollToTop = function (animation = "smooth") {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: animation,
  });
};

function smoothScrollTarget(domTarget, animation = "smooth") {
  if (domTarget) {
    const rect = domTarget.getBoundingClientRect().top;
    const offset = window.pageYOffset;
    const target = rect + offset;
    window.scrollTo({
      top: target,
      behavior: animation,
    });
  }
}

const smoothScrollInit = function () {
  domScrollTriggers = document.querySelectorAll('a[href^="#"]');
  for (let i = 0; i < domScrollTriggers.length; i++) {
    const obj = {
      handleEvent: smoothScrollInPage,
      trigger: domScrollTriggers[i],
    };
    domScrollTriggers[i].addEventListener("click", obj);
    removeObj.push(obj);
  }
};
function smoothScrollRemove() {
  // console.log("smoothScrollRemove");
  for (let i = 0; i < removeObj.length; i++) {
    domScrollTriggers[i].removeEventListener("click", removeObj[i]);
  }
  removeObj = [];
}
function smoothScrollInPage(e) {
  e.preventDefault();
  if (domFrame.classList.contains(clsMenuOpen) == true) {
    navigationClose();
  }
  let href = this.trigger.getAttribute("href");
  let target = document.querySelector(href);
  smoothScrollTarget(target);
}

export { sctollToTop, smoothScrollTarget, smoothScrollInit, smoothScrollRemove };
